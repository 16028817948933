import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getCookie, setCookie } from 'cookies-next';
import { Quiz } from '@components/Modals/Variants/Quiz/Quiz';
interface Props {
  children: ReactNode;
}
export const FeedbackProvider: FC<Props> = ({
  children
}) => {
  const {
    pathname
  } = useRouter();
  const [isOpenModal, setIsOpenModal] = useState(false);
  useEffect(() => {
    if (typeof window === 'undefined' || pathname.includes('/admin')) {
      return;
    }
    const hasViewedQuiz = getCookie('quizViewed');
    if (!hasViewedQuiz) {
      setIsOpenModal(true);
    }
  }, []);
  const handleCloseModal = () => {
    setCookie('quizViewed', 'true', {
      maxAge: 60 * 60 * 24 * 365 * 5,
      // 5 лет
      path: '/'
    });
    setIsOpenModal(false);
  };
  return <>
            {children}
            {isOpenModal && <Quiz closeHandler={handleCloseModal} isLoading={false} />}
        </>;
};